import { defineStore } from 'pinia';

export const useCheckoutStore = defineStore('checkout-store', () => {
  const clientNumber = ref('');
  const clientEmail = ref('');

  return {
    clientNumber,
    clientEmail,
  };
});
